import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Exel from "../../../../saveExcel/Exel";
import ApplicationCatalogue from "../../../../useHook/useTable/components/applicationCatalogue/ApplicationCatalogue";
import button_back from "../../../../style/img/button_back.png";
import "./purchase.css";
const PurchaseDetails = () => {
  const navigate = useNavigate();

  const data = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const invoicesItems =
    data &&
    data.CounterpartyInvoicesItems.map(({ Product, quantity, price }) => ({
      quantity_application: quantity,
      price_sum: quantity * price,
      price,
      ...Product,
    }));
  const invoicesCounterparty = data && data.Counterparty;

  return (
    <div className="purchaseCreate">
      <div className="purchaseCreate_head">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              onClick={() => navigate("/purchase")}
              src={button_back}
              alt="back"
            />
            <span>Заявка</span>
          </div>

          <Exel data={invoicesItems} title={"Накладна"} />
        </div>

        {/* <button onClick={() => saveExcel({ data: invoicesItems })}>Exel</button> */}
      </div>
      <Formik
        initialValues={{
          discount_value: data.discount_value,
          comment: data.comment,
        }}
        validationSchema={Yup.object({})}
        onSubmit={async (body) => {}}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div style={{ width: "25%" }}>
                  <span>Постачальники</span>
                  <div style={{ display: "flex" }}>
                    <span className="purchaseCounterpary">
                      {invoicesCounterparty && invoicesCounterparty.name}
                    </span>
                  </div>
                </div>
                <div>
                  <span>Тип цін</span>
                  <Field disabled type="text" name="discount_value" />
                </div>
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field disabled as="textarea" type="text" name="comment" />
            </div>
          </div>
          <ApplicationCatalogue
            handleCounterApplication={() => {}}
            cancelItemTableFunc={() => {}}
            onChangeApplicationQuantity={() => {}}
            checkedRow={invoicesItems}
            setcheckedRow={() => {}}
            styleTable={{
              minHeight: "calc(100vh - 345px)",
              maxHeight: "calc(100vh - 345px)",
              padding: "0",
            }}
            button={false}
            toggle={false}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default PurchaseDetails;
