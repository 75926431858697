import { Route, Routes, Navigate } from "react-router-dom";

import AsideBarNav from "./nav/AsideBarNav";
import Catalogue from "./catalogue/Catalogue";
import CatalogueCreateProduct from "./catalogue/components/CatalogueCreateProduct";
import CatalogueDetails from "./catalogue/components/CatalogueDetails";
import CatalogueAddingProducts from "./catalogue/components/CatalogueAddingProducts";

import Counterparty from "./counterparty/Counterparty";
import CounterpartyCreate from "./counterparty/components/CounterpartyCreate";

import User from "./user/User";
import UserCreate from "./user/components/UserCreate";

import Stock from "./stock/Stock";
import StockCreate from "./stock/components/StockCreate";
import StockDetails from "./stock/components/StockDetails";

import Purchase from "./purchase/Purchase";
import PurchaseCreate from "./purchase/components/PurchaseCreate";
import PurchaseDetails from "./purchase/components/PurchaseDetails";

import Sell from "./sell/Sell";
import SellCreate from "./sell/components/SellCreate";
import SellDetails from "./sell/components/SellDetails";

import Payment from "./payment/Payment";
import PaymentCreate from "./payment/components/PaymentCreate";
import PaymentDetails from "./payment/components/PaymentDetails";

import CounterpartySearch from "./counterparty/components/CounterpartySearch";

import Setting from "./settingPage/Setting";
import SettingCreate from "./settingPage/components/SettingCreate";

const Main = () => {
  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        width: "100vw",
        background: "#F3FAF8",
      }}
    >
      <AsideBarNav />

      <Routes>
        <Route path="/" element={<Navigate to="/catalogue" replace />} />

        <Route path="/catalogue/*" element={<Catalogue />} />
        <Route path="/catalogue/create" element={<CatalogueCreateProduct />} />
        <Route path="/catalogue/details" element={<CatalogueDetails />} />

        <Route path="/counterparty/*" element={<Counterparty />} />
        <Route path="/counterparty/create" element={<CounterpartyCreate />} />

        <Route path="/users/*" element={<User />} />
        <Route path="/users/create" element={<UserCreate />} />

        <Route path="/stock/*" element={<Stock />} />

        <Route path="/stock/details" element={<StockDetails />} />
        <Route path="/stock/create/*" element={<StockCreate />} />
        <Route
          path="/stock/create/catalouge"
          element={<CatalogueAddingProducts navigate={"/stock/create"} />}
        />
        <Route
          path="/stock/create/search"
          element={<CounterpartySearch addCounerpartyLink={"/stock/create"} />}
        />

        <Route path="/purchase/*" element={<Purchase />} />
        <Route path="/purchase/details" element={<PurchaseDetails />} />
        <Route path="/purchase/create/*" element={<PurchaseCreate />} />
        <Route
          path="/purchase/create/search"
          element={
            <CounterpartySearch
              type={"provider"}
              addCounerpartyLink={"/purchase/create"}
            />
          }
        />
        <Route
          path="/purchase/create/catalouge"
          element={<CatalogueAddingProducts navigate={"/purchase/create"} />}
        />

        <Route path="/sell/*" element={<Sell />} />
        <Route path="/sell/details" element={<SellDetails />} />
        <Route path="/sell/create/*" element={<SellCreate />} />
        <Route
          path="/sell/create/search"
          element={
            <CounterpartySearch
              type={"custom"}
              addCounerpartyLink={"/sell/create"}
            />
          }
        />
        <Route
          path="/sell/create/catalouge"
          element={<CatalogueAddingProducts navigate={"/sell/create"} />}
        />

        <Route path="/payment/*" element={<Payment />} />
        <Route path="/payment/create" element={<PaymentCreate />} />
        <Route path="/payment/details" element={<PaymentDetails />} />
        <Route
          path="/payment/create/search"
          element={
            <CounterpartySearch addCounerpartyLink={"/payment/create"} />
          }
        />

        <Route path="/setting/*" element={<Setting />} />
        <Route path="/setting/create" element={<SettingCreate />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </div>
  );
};

export default Main;
