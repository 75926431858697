import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import button_back from "../../../../style/img/button_back.png";

const PaymentDetails = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const data = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];
  console.log(data);
  const selectedCategory = useSelector((state) => state.payment.activeCategory);

  const titleCreate = (category) => {
    switch (category) {
      case "refill":
        return "Поповнення";
      case "Writing_off":
        return "Списання";
      default:
        return "";
    }
  };
  useEffect(() => {
    setTitle(titleCreate(selectedCategory));
  }, []);
  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/payment")}
          src={button_back}
          alt="back"
        />
        <span>{title}</span>
      </div>
      <Formik
        initialValues={{
          amount: data.amount,
          // currency_type: data.currency_type,
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          //   const data = body;
          //   data.counterparty_id = checkedCounterparty?.id;
          //   data.payment_type = selectedCategory;
          //   data.currency_type = checkedCurrency.name;
          //   const req = await dispatch(createPaymentPost(data));
          //   if (req.payload === 200) {
          //     navigate("/payment");
          //   }
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <span>Контрагент</span>
                  <div
                    className="purchaseCounterpary_wrapper"
                    style={{ display: "flex" }}
                  >
                    <span className="purchaseCounterpary">
                      {data && data?.counterparty_name}
                    </span>
                  </div>
                </div>
                {/* <div>
                  <span>Валюта</span>
                  <Field disabled={true} type="string" name="currency_type" />
                </div> */}
                <div>
                  <span>Кол-во</span>
                  <Field disabled={true} type="number" name="amount" />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default PaymentDetails;
