import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createPaymentPost,
  setCheckedCurrency,
} from "../../../../store/slices/paymentSlice";
import { getSettingByType } from "../../../../store/slices/settingSlice";
import { checkboxForDetailsOrDelete } from "../../../../store/slices/tableSlice";
import AddCounterpartyButton from "../../counterparty/components/AddCounterpartyButton";
import UseDropDown from "../../../../useHook/dropDown/UseDropDown";
import button_back from "../../../../style/img/button_back.png";
import close_red from "../../../../style/img/close_red.png";
const PaymentCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const checkedCounterparty = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];
  const selectedCategory = useSelector((state) => state.payment.activeCategory);

  // currency
  // const currency = useSelector((state) => state.setting.data);
  // const checkedCurrency = useSelector((state) => state.payment.checkedCurrency);
  // const handleCurrency = (item) => {
  //   dispatch(setCheckedCurrency(item));
  // };
  useEffect(() => {
    dispatch(getSettingByType({ type: "currency" }));
  }, []);

  const titleCreate = (category) => {
    switch (category) {
      case "refill":
        return "Поповнення";
      case "Writing_off":
        return "Списання";
      default:
        return "";
    }
  };

  useEffect(() => {
    setTitle(titleCreate(selectedCategory));
    // dispatch(checkboxForDetailsOrDelete([]));
  }, []);
  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/payment")}
          src={button_back}
          alt="back"
        />
        <span>{title}</span>
      </div>
      <Formik
        initialValues={{
          amount: "",
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.counterparty_id = checkedCounterparty?.id;
          data.payment_type = selectedCategory;
          // data.currency_type = checkedCurrency.name;
          const req = await dispatch(createPaymentPost(data));
          if (req.payload === 200) {
            navigate("/payment");
          }
          // currency_type,
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <AddCounterpartyButton
                  checkedCounterparty={checkedCounterparty}
                  title={"Контрагент"}
                  navigateTo={"/payment/create/search"}
                />
                {/* <div>
                  <span>Валюта</span>
                  <UseDropDown
                    selectedItem={checkedCurrency}
                    handleChange={handleCurrency}
                    data={currency}
                  />
                </div> */}
                <div>
                  <span>Кол-во</span>
                  <Field type="number" name="amount" />
                </div>
              </div>
            </div>
          </div>

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default PaymentCreate;
