import Excel from "exceljs";
import { saveAs } from "file-saver";

export const columnApplication = [
  {
    header: "Вибраний товар",
    key: "name",
  },

  {
    header: "Кількість",
    key: "quantity_application",
  },
  {
    header: "Сума",
    key: "price_sum",
  },
];

export const saveExcel = async ({
  data,
  columns = columnApplication,
  title,
}) => {
  const makeData = data.map((i, index) => {
    return { index: index + 1, ...i };
  });
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}.${month}.${year}`;
  };
  const workSheetName = "Worksheet-1";
  const workBookName = `Отчет ${formatDate(new Date())}`;
  const workbook = new Excel.Workbook();
  try {
    const fileName = workBookName;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // Add invoice header
    worksheet.mergeCells("A1", "C1");
    worksheet.getCell("A1").value = title;
    worksheet.getCell("A1").font = { bold: true, size: 16 };
    worksheet.getCell("A1").alignment = { horizontal: "center" };

    // Add invoice information
    worksheet.addRow(["Фирма:", "Mobel"]);
    worksheet.addRow(["Кому:", "Agent"]);
    worksheet.addRow(["Дата:", formatDate(new Date())]);
    // Add empty row for spacing
    worksheet.addRow([]);
    worksheet.addRow(["Место для подписи:", ""]);
    // Add empty row for spacing
    worksheet.addRow([]);

    // Merge cells for the product title
    worksheet.mergeCells("A7", "C7");
    worksheet.getCell("A7").value = "ТОВАРЫ";
    worksheet.getCell("A7").font = { bold: true, size: 16 };
    worksheet.getCell("A7").alignment = { horizontal: "center" };

    // Add table header
    const tableHeaderRow = worksheet.addRow(
      columns.map((column) => column.header)
    );
    tableHeaderRow.font = { bold: true };

    // Add table data
    makeData.forEach((rowData) => {
      const row = worksheet.addRow(
        columns.map((column) => rowData[column.key])
      );
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    // Add borders to all cells in the table
    worksheet.getRows(7, 7 + makeData.length).forEach((row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    worksheet.columns.forEach((column) => {
      column.width = 30;
    });
    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error("<<<ERROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
