export const columnsTableCatalouge = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Артикул",
    accessor: "vendor_code",
  },
  {
    Header: "Найменування",
    accessor: "name",
  },
  {
    Header: "Ціна",
    accessor: "price",
  },
  {
    Header: "Резерв",
    accessor: "boking_items",
  },
  {
    Header: "Залишок",
    accessor: "balance",
  },
  {
    Header: "Од",
    accessor: "measurement",
  },
];
// { id: 1, name: "test", price: 10, boking_items: 3, balance: 10, od: "шт" },
