import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { putProduct } from "../../../../store/slices/productSlice";
import button_back from "../../../../style/img/button_back.png";
import "./catalogueCreate.css";

const CatalogueDetails = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const data = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/catalogue")}
          src={button_back}
          alt="back"
        />
        <span>Новий товар</span>
      </div>
      <Formik
        initialValues={{
          name: data.name,
          full_name: data.full_name,
          view: data.view,
          type: data.type,
          vendor_code: data.vendor_code,
          code: data.code,
          comment: data.comment,

          // second
          currency: data.currency,
          measurement: data.measurement,
          weight: data.weight,
          barcode: data.barcode,
          price: data.price,

          VAT: data.VAT,
          VAT_exemption: data.VAT_exemption,
          quo_for_NN: data.quo_for_NN,
          markup: data.markup,
          min_balance: data.min_balance,
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const req = await dispatch(
            putProduct({ data: body, product_id: data.id })
          );
          if (req.payload === 200) setEdit(false);
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <span>Найменування</span>
                  <Field type="text" disabled={!edit} name="name" />
                </div>
                <div>
                  <span>Повне найменування</span>
                  <Field type="text" disabled={!edit} name="full_name" />
                </div>

                {/* <ErrorMessage name="login" className="error " component="div" /> */}
              </div>
              <div className="catalogueCreate_input_top_second">
                <div>
                  <span>Вид</span>
                  <Field type="text" disabled={!edit} name="view" />
                </div>
                <div>
                  <span>Тип</span>
                  <Field type="text" disabled={!edit} name="type" />
                </div>
                <div>
                  <span>Артикул</span>
                  <Field type="text" disabled={!edit} name="vendor_code" />
                </div>
                <div>
                  <span>Код</span>
                  <Field type="text" disabled={!edit} name="code" />
                </div>
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field
                as="textarea"
                disabled={!edit}
                type="text"
                name="comment"
              />
            </div>
          </div>

          <div className="catalogueCreate_input_bottom_wrapper">
            <div className="catalogueCreate_input_bottom">
              <div>
                <span>Валюта обліку</span>
                <Field type="text" disabled={!edit} name="currency" />
              </div>
              <div>
                <span>Базова одиниця виміру</span>
                <Field type="text" disabled={!edit} name="measurement" />
              </div>
              <div>
                <span>Вага (кг.)</span>
                <Field type="text" disabled={!edit} name="weight" />
              </div>
              <div>
                <span>Штрих код</span>
                <Field type="text" disabled={!edit} name="barcode" />
              </div>
              <div>
                <span>Облікова ціна</span>
                <Field type="text" disabled={!edit} name="price" />
              </div>
            </div>
            <div className="catalogueCreate_input_bottom">
              <div>
                <span>НДС</span>
                <Field type="text" disabled={!edit} name="VAT" />
              </div>
              <div>
                <span>Пільга з НДС</span>
                <Field type="text" disabled={!edit} name="VAT_exemption" />
              </div>
              <div>
                <span>Кво для НН</span>
                <Field type="text" disabled={!edit} name="quo_for_NN" />
              </div>
              <div>
                <span>Торг націнки</span>
                <Field type="text" disabled={!edit} name="markup" />
              </div>
              <div>
                <span>Мінімальний залишок</span>
                <Field type="text" disabled={!edit} name="min_balance" />
              </div>
            </div>
          </div>

          <div className="detailsButton_wrapper">
            <div>
              {edit && (
                <button className="catalogueCreate_submit" type="submit">
                  Зберегти
                </button>
              )}
            </div>

            <button
              type="button"
              className="catalogueCreate_submit"
              onClick={() => setEdit((state) => !state)}
            >
              Редагувати
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default CatalogueDetails;
