import React from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createProductPost } from "../../../../store/slices/productSlice";
import button_back from "../../../../style/img/button_back.png";
import "./catalogueCreate.css";
const CatalogueCreateProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedCategoryGroup = useSelector(
    (state) => state.table.selectedCategoryGroup
  );
  const { error, loading } = useSelector((state) => state.product);

  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/catalogue")}
          src={button_back}
          alt="back"
        />
        <span>Новий товар</span>
        {error && (
          <span style={{ marginLeft: "100px" }} className="error">
            {error}
          </span>
        )}
      </div>
      <Formik
        initialValues={{
          name: "",
          full_name: "",
          view: "",
          type: "",
          vendor_code: "",
          // code: "",
          comment: "",

          // second
          currency: "",
          measurement: "",
          weight: "",
          barcode: "",
          price: "",

          VAT: "",
          VAT_exemption: "",
          quo_for_NN: "",
          markup: "",
          min_balance: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          // full_name: Yup.string().required("Full name is required"),
          // view: Yup.string().required("View is required"),
          // type: Yup.string().required("Type is required"),
          vendor_code: Yup.string().required("Vendor code is required"),
          // code: Yup.string().required("Code is required"),
          // comment: Yup.string().required("Comment is required"),
          // currency: Yup.string().required("Currency is required"),
          // measurement: Yup.string().required("Measurement is required"),
          // weight: Yup.string().required("Weight is required"),
          // barcode: Yup.string().required("Barcode is required"),
          price: Yup.string().required("Price is required"),
          // VAT: Yup.string().required("VAT is required"),
          // VAT_exemption: Yup.string().required("VAT exemption is required"),
          // quo_for_NN: Yup.string().required("Quo for NN is required"),
          // markup: Yup.string().required("Markup is required"),
          // min_balance: Yup.string().required("Min balance is required"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.categories_id = selectedCategoryGroup;
          const req = await dispatch(createProductPost(data));

          if (req.payload === 200) navigate("/catalogue");
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <div className="wrapperSpanAndError">
                    <span>Найменування</span>
                    <ErrorMessage
                      name="name"
                      className="error"
                      component="span"
                    />
                  </div>

                  <Field type="text" name="name" />
                </div>
                <div>
                  <span>Повне найменування</span>
                  <Field type="text" name="full_name" />
                </div>

                {/* <ErrorMessage name="login" className="error " component="div" /> */}
              </div>
              <div className="catalogueCreate_input_top_second">
                <div>
                  <span>Вид</span>
                  <Field type="text" name="view" />
                </div>
                <div>
                  <span>Тип</span>
                  <Field type="text" name="type" />
                </div>
                <div>
                  <span>Артикул</span>
                  <Field type="text" name="vendor_code" />
                </div>
                {/* <div>
                  <div className="wrapperSpanAndError">
                    <span>Код</span>
                    <ErrorMessage
                      name="code"
                      className="error"
                      component="span"
                    />
                  </div>

                  <Field type="text" name="code" />
                </div> */}
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field as="textarea" type="text" name="comment" />
            </div>
          </div>

          <div className="catalogueCreate_input_bottom_wrapper">
            <div className="catalogueCreate_input_bottom">
              <div>
                <span>Валюта обліку</span>
                <Field type="text" name="currency" />
              </div>
              <div>
                <span>Базова одиниця виміру</span>
                <Field type="text" name="measurement" />
              </div>
              <div>
                <span>Вага (кг.)</span>
                <Field type="text" name="weight" />
              </div>
              <div>
                <span>Штрих код</span>
                <Field type="text" name="barcode" />
              </div>
              <div>
                <div className="wrapperSpanAndError">
                  <span>Облікова ціна</span>
                  <ErrorMessage
                    name="price"
                    className="error"
                    component="span"
                  />
                </div>

                <Field type="text" name="price" />
              </div>
            </div>
            <div className="catalogueCreate_input_bottom">
              <div>
                <span>НДС</span>
                <Field type="text" name="VAT" />
              </div>
              <div>
                <span>Пільга з НДС</span>
                <Field type="text" name="VAT_exemption" />
              </div>
              <div>
                <span>Кво для НН</span>
                <Field type="text" name="quo_for_NN" />
              </div>
              <div>
                <span>Торг націнки</span>
                <Field type="text" name="markup" />
              </div>
              <div>
                <span>Мінімальний залишок</span>
                <Field type="text" name="min_balance" />
              </div>
            </div>
          </div>

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default CatalogueCreateProduct;
