import { useState } from "react";
import UseModal from "../../../../UseModal";
import { useDispatch } from "react-redux";
import { createCategoryTable } from "../../../../../store/slices/tableSlice";

const ModalTableNavCreate = ({ createNewCategory }) => {
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });
  const [name, setName] = useState("");
  const handleChange = (e) => {
    setName(e.target.value);
  };
  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };

  const submitCreate = async () => {
    if (name.length > 1) {
      const req = await dispatch(createCategoryTable({ name: name }));
      if (req.payload === 200) closeModal();
    }
  };

  return (
    createNewCategory && (
      <>
        <button className="modalCreateButton" onClick={() => onModal()}>
          Створити категорію
        </button>

        <UseModal
          visible={modalCreate.visible}
          title="Створити категорію"
          content={
            <div className="modalCreate_wrapper">
              <span>Назва категорії</span>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  submitCreate();
                }}
              >
                <input id="name" value={name} onChange={handleChange} />
                <button>Додати категорію</button>
              </form>
            </div>
          }
          onClose={closeModal}
        />
      </>
    )
  );
};

export default ModalTableNavCreate;
