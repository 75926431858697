import React, { useEffect, useState } from "react";
import UseTable from "../../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { categoryData } from "./categoryData";
import { columnsTableCounterparty } from "./columns";
import { getCounterpartiesByType } from "../../../../store/slices/counterpartySlice";

const CounterpartySearch = ({ type = null, addCounerpartyLink }) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);
  const tableData = useSelector((state) => state.counterparty.counterparties);
  const totalPages = useSelector(
    (state) => state.counterparty.totalCounterparty
  );

  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (categoryData.find(({ id }) => id === selectedCategory || type)) {
      const typeData = type ? type : selectedCategory;
      dispatch(
        getCounterpartiesByType({
          type: typeData,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
        })
      );
    }
  }, [currentPage, selectedCategory]);

  //  state filters
  const [dataInput, setDataInput] = useState({
    driver_name: "",
  });
  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      table_nav={type ? false : true}
      tableNavLebel={"Контрагенти"}
      data_nav={categoryData}
      data={tableData}
      columns={columnsTableCounterparty}
      onChangeData={onChangeData}
      dataInput={dataInput}
      deleteButton={false}
      movedButton={false}
      addCounerpartyButton={true}
      addCounerpartyLink={addCounerpartyLink}
    />
  );
};

export default CounterpartySearch;
