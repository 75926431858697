export const columnsTableCounterparty = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Код",
    accessor: "code",
  },
  {
    Header: "ПІБ",
    accessor: "name",
  },
  {
    Header: "Телефон",
    accessor: "phone",
  },
  {
    Header: "Баланс",
    accessor: "balance",
  },
];
// { id: 1, name: "test", price: 10, boking_items: 3, balance: 10, od: "шт" },
