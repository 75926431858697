import React, { useEffect, useState } from "react";
import UseTable from "../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { columnsTablePurchase } from "./components/columns";
import { makeUrl } from "../../../useHook/useTable/components/makeUrl";
// import axios, { API_URL } from "../../../axios";
// import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  getCounterpartiesInvoicesByType,
  cancelCounterpartiesInvoice,
} from "../../../store/slices/counterpartySlice";
const Sell = () => {
  const dispatch = useDispatch();

  const totalPages = useSelector((state) => state.counterparty.totalInvoices);
  const tableData = useSelector((state) => state.counterparty.invoices);

  //  state filters
  const [dataInput, setDataInput] = useState({
    id: "",
    user_name: "",
    counterparty_name: "",
    price_sum: "",
  });
  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      getCounterpartiesInvoicesByType({
        type: "sale",
        offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
        search: makeUrl(dataInput),
      })
    );
  }, [currentPage, dataInput]);

  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  const deleteFunc = async (ids) => {
    const req = await dispatch(cancelCounterpartiesInvoice(ids));
    if (req.payload === 200) {
      dispatch(
        getCounterpartiesInvoicesByType({
          type: "sale",
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl(dataInput),
        })
      );
    }
    return req;
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const controller = new AbortController();
  //   const connectEventSource = async () => {
  //     await fetchEventSource(`${API_URL}/connect_invoice`, {
  //       headers,
  //       signal: controller.signal,
  //       async onopen(response) {
  //         if (response.status === 401) {
  //           const response = await axios.get(`${API_URL}/refresh`, {
  //             withCredentials: true,
  //           });
  //           localStorage.setItem("token", response.data.acsses);
  //           // connectEventSource();
  //         }
  //       },
  //       onmessage() {
  //         dispatch(
  //           getCounterpartiesInvoicesByType({
  //             type: "sale",
  //             offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
  //           })
  //         );
  //       },
  //     });
  //   };
  //   connectEventSource();
  //   return () => controller.abort();
  // }, [currentPage]);

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      movedButton={false}
      data={tableData}
      columns={columnsTablePurchase}
      onChangeData={onChangeData}
      dataInput={dataInput}
      addLink={{ to: "/sell/create", label: "Нова накладна" }}
      detailsLink={"/sell/details"}
      deleteFunc={deleteFunc}
      dateRange={true}
    />
  );
};

export default Sell;
