export const columnsTablePayment = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Код",
    accessor: "id",
  },
  {
    Header: "Автор",
    accessor: "user_name",
  },
  {
    Header: "Контрагент",
    accessor: "counterparty_name",
  },
  {
    Header: "Кол-во",
    accessor: "amount",
  },
  {
    Header: "Дата",
    accessor: "date",
  },
  {
    Header: "Час",
    accessor: "hours",
  },
];
