import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { createCounterpartiesInvoice } from "../../../../store/slices/counterpartySlice";
import {
  handleCounterCheckboxData,
  cancelItemCheckboxData,
  checkboxForDetailsOrDelete,
  setCheckboxData,
  onChangeCheckboxData,
} from "../../../../store/slices/tableSlice";
import {
  setCheckedDiscount,
  getSettingByType,
} from "../../../../store/slices/settingSlice";
import UseDropDown from "../../../../useHook/dropDown/UseDropDown";
import AddCounterpartyButton from "../../counterparty/components/AddCounterpartyButton";
import ApplicationCatalogue from "../../../../useHook/useTable/components/applicationCatalogue/ApplicationCatalogue";

import button_back from "../../../../style/img/button_back.png";

const SellCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const discounts = useSelector((state) => state.setting.data);
  const checkedRow = useSelector((state) => state.table.checkboxData);

  const CheckedDiscount = useSelector((state) => state.setting.checkedDiscount);
  const productsData = checkedRow
    ? checkedRow.map(({ price_sum, price, quantity_application, ...rest }) => ({
        price_sum:
          price_sum +
          (quantity_application * price * CheckedDiscount?.value) / 100,
        price,
        quantity_application,
        ...rest,
      }))
    : [];

  const handleDiscount = (item) => {
    dispatch(setCheckedDiscount(item));
  };
  const checkedCounterparty = useSelector(
    (state) => state.table.checkboxForDetailsOrDelete
  )[0];

  const toggleRedaxData = (accessor, id) => {
    dispatch(handleCounterCheckboxData(accessor, id));
  };
  const cancelItemRedaxData = (id) => {
    dispatch(cancelItemCheckboxData(id));
  };
  const onChangeApplicationQuantityRedaxData = ({ value, id }) => {
    dispatch(onChangeCheckboxData({ value, id }));
  };
  useEffect(() => {
    dispatch(getSettingByType({ type: "discount" }));
  }, []);
  // useEffect(() => {
  //   if (checkedCounterparty && checkedCounterparty.type !== "custom") {
  //     dispatch(checkboxForDetailsOrDelete([]));
  //   }
  // }, []);

  return (
    <div className="purchaseCreate">
      <div className="purchaseCreate_head">
        <img onClick={() => navigate("/sell")} src={button_back} alt="back" />
        <span>Нова накладна</span>
      </div>
      <Formik
        initialValues={{ comment: "" }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.items = checkedRow.map(({ id, quantity_application }) => ({
            id,
            quantity: quantity_application,
          }));
          data.counterparty_id = checkedCounterparty?.id;
          data.type = "sale";
          data.discount_value = CheckedDiscount ? CheckedDiscount.value : 0;
          const req = await dispatch(createCounterpartiesInvoice(data));

          if (req.payload === 200) {
            dispatch(setCheckboxData([]));
            dispatch(checkboxForDetailsOrDelete([]));
            navigate("/sell");
          }
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <AddCounterpartyButton
                  checkedCounterparty={checkedCounterparty}
                  title={"Клієнт"}
                  navigateTo={"/sell/create/search"}
                />

                <div>
                  <span>Тип цін</span>
                  <UseDropDown
                    selectedItem={CheckedDiscount}
                    handleChange={handleDiscount}
                    data={discounts}
                  />
                </div>

                {/* <ErrorMessage name="login" className="error " component="div" /> */}
              </div>
            </div>
            <div className="catalogueCreate_input_top_textarea">
              <span>Коментар</span>
              <Field as="textarea" type="text" name="comment" />
            </div>
          </div>

          <ApplicationCatalogue
            handleCounterApplication={toggleRedaxData}
            onChangeApplicationQuantity={onChangeApplicationQuantityRedaxData}
            cancelItemTableFunc={cancelItemRedaxData}
            checkedRow={productsData}
            setcheckedRow={() => {}}
            styleTable={{
              minHeight: "calc(100vh - 400px)",
              maxHeight: "calc(100vh - 400px)",
              padding: "0",
            }}
            button={false}
          />
          <div className="purchaseCreate_buttonWrapper">
            <button className="catalogueCreate_submit" type="submit">
              Додати
            </button>
            <button
              onClick={() => navigate("/sell/create/catalouge")}
              className="catalogueCreate_submit"
            >
              За каталогом
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SellCreate;
