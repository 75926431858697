import { useState } from "react";
import UseModal from "../../../../UseModal";
import { ReactComponent as SettingSvg } from "../../../../../style/img/setting.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategoryTable,
  deleteCatagory,
  getCategoryTable,
} from "../../../../../store/slices/tableSlice";
import ModalDelete from "../../ModalDelete";

const TableNavSetting = ({ parentSub_id }) => {
  const categoryData = useSelector((state) => state.table.category);
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });
  const [name, setName] = useState("");
  const handleChange = (e) => {
    setName(e.target.value);
  };
  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };
  const submitCreate = async () => {
    if (name.length > 2) {
      const req = await dispatch(
        createCategoryTable({ name: name, parentSub_id: parentSub_id })
      );
      if (req.payload === 200) closeModal();
    }
  };

  const findTreeRootAndIdsByTargetId = (categories, targetId) => {
    let categoryIds = [];
    let foundTarget = false;

    const findCategory = (category) => {
      if (category.id === targetId) {
        foundTarget = true;
      }

      if (foundTarget) {
        categoryIds.push(category.id);
      }

      if (category.subMenu) {
        for (const subCategory of category.subMenu) {
          findCategory(subCategory);
        }
      }

      return foundTarget;
    };

    for (const category of categories) {
      if (findCategory(category)) {
        break;
      }
    }

    return categoryIds;
  };

  const submitDelete = async (id) => {
    const req = await dispatch(
      deleteCatagory(findTreeRootAndIdsByTargetId(categoryData, id))
    );
    if (req.payload === 200) {
      dispatch(getCategoryTable());
    }
    return req;
  };

  return (
    <>
      <div className="tableNav_setting_button" onClick={() => onModal()}>
        <SettingSvg />
      </div>

      <UseModal
        visible={modalCreate.visible}
        title="Створити підкатегорії"
        content={
          <div className="modalCreate_wrapper">
            <span>Назва підкатегорії</span>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              onSubmit={(e) => {
                e.preventDefault();
                submitCreate();
              }}
            >
              <input id="name" value={name} onChange={handleChange} />
              <button>Додати підкатегорії</button>
            </form>
            <div
              className="modal_tableNav_buttonWrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button onClick={closeModal}>Cкасувати</button>
              <ModalDelete
                submit={submitDelete}
                checkedRowLength={1}
                checkedIds={parentSub_id}
              />
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default TableNavSetting;
