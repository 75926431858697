import React, { useState } from "react";
import "./dropdown.css";

const UseDropDown = ({ selectedItem, handleChange, data }) => {
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  return (
    <div className="lang_dropdown">
      <div className="lang_dropdown-header" onClick={toggleDropdown}>
        <span className="purchaseCounterpary">{selectedItem?.name}</span>
      </div>
      <div className={`lang_dropdown-body ${isOpen && "open"}`}>
        {data.map((item) => (
          <div
            key={item.id}
            className="lang_dropdown-item"
            onClick={() => {
              handleChange(item);
              toggleDropdown();
            }}
            id={item.id.toString()}
          >
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseDropDown;
