import React, { useState, useEffect } from "react";
import { ReactComponent as BugerIcon } from "../../../../../style/img/burger_icon.svg";
import TableNavSetting from "./TableNavSetting";
import { useSelector } from "react-redux";
const RenderMenuItem = ({
  menuItem,
  onSelect,
  selectedCategory,
  createNewCategory,
  styleWrapper = true,
}) => {
  const openCategoryStore = useSelector(
    (state) => state.table.selectedCategoryGroup
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      openCategoryStore &&
      openCategoryStore.length > 0 &&
      openCategoryStore.includes(menuItem.id)
    ) {
      setOpen(true);
    }
  }, []);
  const handleOpen = (item) => {
    onSelect(item.id);

    if (selectedCategory === item.id) {
      setOpen(!open);
    } else setOpen(true);
  };
  const handleSelect = (id) => {
    onSelect(id);
  };
  if (menuItem.subMenu) {
    return (
      <div
        className="table_sideitem_wrapper"
        style={
          open && styleWrapper
            ? {
                borderTop: "1px solid #F0F0F0",
                borderBottom: " 1px solid #F0F0F0",
                padding: "5px 0",
              }
            : {}
        }
      >
        <div
          className={`table_sideitem ${open && "active"} ${
            selectedCategory === menuItem.id && "selected"
          }`}
          style={
            selectedCategory === menuItem.id
              ? { background: "#0D7040" }
              : undefined
          }
        >
          {menuItem.icon}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              width: "100%",
            }}
            onClick={() => handleOpen(menuItem)}
          >
            <span className="table_sideitem_titel">{menuItem.name}</span>
            <BugerIcon />
          </div>

          {selectedCategory === menuItem.id && createNewCategory && (
            <TableNavSetting parentSub_id={menuItem.id} />
          )}
        </div>

        <div
          className="table_aside_submenu"
          style={open ? { display: "block" } : undefined}
        >
          {menuItem.subMenu.map((subItem) => {
            if (subItem.subMenu) {
              return (
                <RenderMenuItem
                  key={subItem.id}
                  menuItem={subItem}
                  onSelect={onSelect}
                  selectedCategory={selectedCategory}
                  createNewCategory={createNewCategory}
                  styleWrapper={false}
                />
              );
            }
            return (
              <div
                key={subItem.id}
                onClick={() => handleSelect(subItem.id)}
                className={`table_aside_submenu_wrapper ${
                  selectedCategory === subItem.id && "active"
                }`}
              >
                <span className="table_aside_submenu_text" key={subItem.id}>
                  {subItem.name}
                </span>
                {selectedCategory === subItem.id && createNewCategory && (
                  <TableNavSetting parentSub_id={subItem.id} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      key={menuItem.id}
      onClick={() => handleSelect(menuItem.id)}
      className={`table_sideitem ${
        selectedCategory === menuItem.id && "selected"
      }`}
      style={
        selectedCategory === menuItem.id ? { background: "#0D7040" } : undefined
      }
    >
      {menuItem.icon}
      <span style={{ padding: "10px" }} className="table_sideitem_titel">
        {menuItem.name}
      </span>
      {selectedCategory === menuItem.id &&
        menuItem.id !== "without" &&
        createNewCategory &&
        selectedCategory !== null && (
          <TableNavSetting parentSub_id={menuItem.id} />
        )}
    </div>
  );
};

export default RenderMenuItem;
