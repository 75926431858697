export const categoryData = [
  { id: "arrival", name: "Прибуткові накладні" },
  { id: "return", name: "Повернення" },
  { id: "writeOff", name: "Акт списання" },
  { id: "writeOn", name: "Акт оприбуткування" },
  { id: "booking", name: "Резерв" },
];

// const typeTransitions = {
//   return: "Возврат",
//   arrival: "Приходная",
//   writeOff: "Списание",
//   writeOn: "Добавление плюс",
// };
