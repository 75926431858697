import React, { useEffect, useState } from "react";
import UseTable from "../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import { columnsTableCatalouge } from "./components/columns";
import { getCategoryTable } from "../../../store/slices/tableSlice";
import {
  getProductsByCatagory,
  deleteProducts,
} from "../../../store/slices/productSlice";
import { makeUrl } from "../../../useHook/useTable/components/makeUrl";
// import axios, { API_URL } from "../../../axios";
// import { fetchEventSource } from "@microsoft/fetch-event-source";

const Catalogue = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.table.selectedCategory);
  const categoryData = useSelector((state) => state.table.category);
  const tableData = useSelector((state) => state.product.products);
  const totalPages = useSelector((state) => state.product.totalProducts);
  //  state filters
  const [dataInput, setDataInput] = useState({
    code: "",
    name: "",
    price: "",
    measurement: "",
  });
  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getCategoryTable());
  }, []);

  // change in future versions
  function getAllCategoryIds(categories) {
    const ids = [];

    function collectIds(category) {
      ids.push(category.id);
      if (category.subMenu && category.subMenu.length > 0) {
        category.subMenu.forEach((subCategory) => collectIds(subCategory));
      }
    }

    categories.forEach((category) => collectIds(category));

    return ids;
  }
  useEffect(() => {
    if (getAllCategoryIds(categoryData).includes(selectedCategory)) {
      dispatch(
        getProductsByCatagory({
          category: selectedCategory,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl(dataInput),
        })
      );
    }
  }, [selectedCategory, currentPage, dataInput]);

  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  const deleteFunc = async (ids) => {
    const req = await dispatch(deleteProducts(ids));

    if (req.payload === 200) {
      dispatch(
        getProductsByCatagory({
          category: selectedCategory,
          offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
          search: makeUrl(dataInput),
        })
      );
    }
    return req;
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const controller = new AbortController();
  //   const connectEventSource = async () => {
  //     await fetchEventSource(`${API_URL}/connect_catalogue`, {
  //       headers,
  //       signal: controller.signal,
  //       async onopen(response) {
  //         if (response.status === 401) {
  //           const response = await axios.get(`${API_URL}/refresh`, {
  //             withCredentials: true,
  //           });
  //           localStorage.setItem("token", response.data.acsses);
  //         }
  //       },
  //       async onmessage() {
  //         dispatch(getCategoryTable());

  //         dispatch(
  //           getProductsByCatagory({
  //             category: selectedCategory,
  //             offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
  //           })
  //         );
  //       },
  //     });
  //   };
  //   connectEventSource();
  //   return () => {
  //     controller.abort();
  //   };
  // }, [selectedCategory, currentPage]);

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      table_nav={true}
      data_nav={categoryData}
      data={tableData}
      columns={columnsTableCatalouge}
      onChangeData={onChangeData}
      dataInput={dataInput}
      tableNavLebel={"Каталог"}
      createNewCategory={true}
      addLink={{ to: "/catalogue/create", label: "Додати товар" }}
      detailsLink={"/catalogue/details"}
      deleteFunc={deleteFunc}
      movedButton={false}
    />
  );
};

export default Catalogue;
